import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import Theme from '../Theme/Theme';
import { Grid, Typography } from '@material-ui/core';
import AppBarFullSizeImage from '../AppBar/AppBarFullSizeImage';



const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    backgroundColor: "#F7F7F7",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    backgroundColor: "#F7F7F7",
  },
  footerContent: {
    textAlign: "center",
    paddingTop: "5em",
    paddingBottom: "5em",
    backgroundColor: "#F7F7F7",
  },
  line: {
    border: "None",
    height: "1px",
    color: "#707070",
    backgroundColor: "#707070",
  }
}));

const SectionTitle = withStyles({
  root: {
    fontFamily: "Roboto",
    fontWeight: "Medium"
  }
})(Typography);
export default function DashboardLayout(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const layoutStore = useSelector(state => state.layout);
  useEffect(() => {
    setOpen(false)
  }, []);
  return (

    <div >
      <CssBaseline />
      <AppBarFullSizeImage open={open} />

      <main
        id="scroll-container"
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {props.children}
      </main>

      <Grid className={classes.footerContent}>
        <hr className={classes.line} size="10" />
        <SectionTitle color="secondary">
          +90 212 603 20 13
          </SectionTitle>
        <SectionTitle color="secondary">    TERMS   I   PRIVACY    I   COOKIES</SectionTitle>
        <SectionTitle color="secondary">    © COPYRIGHT SERKAN AHLAT 2021. ALL RIGHTS RESERVED</SectionTitle>
      </Grid >
    </div >
  )
}
