import React from "react";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      background: '#f3f2f0',
    },
    secondary: {
      main: '#707070'
    },
    tertiary: {
      main: '#FFFFFF'
    },
    textPrimary: {
      primaryColor: '#707070'
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Halve',
      'Helvetica Neue',
      'Italianno'
    ].join(','),
  }
});



const responsiveTheme = responsiveFontSizes(theme);


const Theme = ({ children }) => (
  <ThemeProvider theme={responsiveTheme}>{children}</ThemeProvider>
);

export default Theme;