import React from 'react'
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Card, CardMedia, Hidden } from '@material-ui/core';
import CollectionSection from './CollectionSection';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: "center",
    },
    modelAndPresentation: {
        display: 'flex',
        flexDirection: 'row'
    },
    sections: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    },
    presentation: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),

    },
    banner: {

    }
}));

const PresentationText = withStyles({
    root: {
        fontFamily: "Helvetica Neue"
    }
})(Typography);

const TitleText = withStyles({
    root: {
        fontFamily: "Italianno"
    }
})(Typography);

function CollectionPage(props) {
    const classes = useStyles();
    const Collection = useSelector(state => state.layout).Collections[props.match.params.CollectionName];
    return (
        <Grid
            alignItems="center"
            justify="center"
            className={classes.container}
        >
            {Collection.banner != "" &&
                < Image className={classes.banner} aspectRatio={1151 / 375}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/${Collection.banner}`} />
            }


            <Grid>
                <TitleText variant="h2"> {Collection.title} </TitleText>
            </Grid>
            <Grid className={classes.sections}>
                <CollectionSection
                    CollectionName={props.match.params.CollectionName}
                    data={Collection.section1} />
            </Grid>

            {
                Collection.modelImage1 != "" &&
                <Image className={classes.banner} aspectRatio={1144 / 418}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/${Collection.modelImage1}`}
                />
            }

            <Grid className={classes.sections}>
                <CollectionSection
                    CollectionName={props.match.params.CollectionName}
                    data={Collection.section2} />
            </Grid>
            <Grid className={classes.modelAndPresentation}>
                <Grid container
                    alignItems="center"
                    justify="center"
                    md={Collection.presentation != "" ? 6 : 12} className={classes.presentation}>
                    <PresentationText
                        variant="subtitle1"
                        align="center"
                    >
                        FOLKSY COLLECTION
                        <br /><br />

                        Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.
                        Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan
                        lacus vel facilisis.
                        <br />
                        <br />
                        Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.
                        Quis ipsum suspendisse ultrices gravida.
                        Risus commodo viverra maecenas accumsan
                        lacus vel facilisis.
                    </PresentationText>
                </Grid>
                <Hidden xsDown>

                    {
                        Collection.presentation != "" &&
                        <Grid md={6} className={classes.model}>
                            <Image aspectRatio={553 / 725}
                                src={`${process.env.REACT_APP_PUBLIC_URL}/${Collection.presentation}`} />
                        </Grid>
                    }


                </Hidden>
            </Grid>
        </Grid >
    )
}

export default CollectionPage;
