import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme) => ({
    listItem: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            borderBottom: "1px solid #CECACA",
            borderRadius: 0
        },
    }
}));

const MenuText = withStyles((theme) => ({
    root: {
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            float: "right"
        },
    }
}))(Typography);

export default function MenuItem(props) {
    const classes = useStyles();

    return (
        <Button className={classes.listItem} button to={props.path} component={RouterLink}>
            <MenuText align="right" color="secondary" >
                {props.name}
            </MenuText>
        </Button>
    )


}
