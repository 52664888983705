import React from 'react'
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Card, CardMedia, Hidden } from '@material-ui/core';
import Detail from './Detail';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: "center",
    },
    modelAndPresentation: {
        display: 'flex',
        flexDirection: 'row'
    },
    sections: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    },
    presentation: {
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),

    },
    banner: {

    }
}));

const PresentationText = withStyles({
    root: {
        fontFamily: "Helvetica Neue"
    }
})(Typography);

const TitleText = withStyles({
    root: {
        fontFamily: "Italianno"
    }
})(Typography);

function List(props) {
    const classes = useStyles();
    const Collection = useSelector(state => state.layout).AllJewellers[props.match.params.JewellersName];
    return (
        <Grid
            alignItems="center"
            justify="center"
            className={classes.container}
        >


            <Grid className={classes.sections}>
                <Detail
                 
                    data={Collection} />
            </Grid>
        </Grid >
    )
}

export default List;
