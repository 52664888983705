import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import { Link as RouterLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    Image: {
        minWidth: "4vh"
    },
    cardText: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
    }
}));

const CardText = withStyles({
    root: {
        fontFamily: "Roboto",
    }
})(Typography);

function Detail(props) {
    const classes = useStyles();
    return (
        <Grid
            container
            alignItems="center"
            justify="center">
            {props.data &&
                props.data.map((card) => {
                    return (
                        <Grid
                            className={classes.cardContainer}
                            md={3}
                            xs={6}
                            key={card.name}
                            to={`/ProductDetail/${card.CollectionName}/${card.ProductId}`}
                            component={RouterLink}
                            style={{ textDecoration: 'none' }}
                        >
                            <Grid xs={11}>
                                <Image aspectRatio={270 / 254}
                                    className={classes.Image}
                                    src={`${process.env.REACT_APP_PUBLIC_URL}/${card.Image}`} />
                            </Grid>
                            <CardText
                                align="center"
                                color="secondary"
                                noWrap
                                className={classes.cardText} variant="p" >
                                {card.name}
                            </CardText>
                        </Grid>
                    )
                })}

        </Grid>
    )
}

export default Detail;