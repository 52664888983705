import React from 'react'
import { Button, Popover, Typography } from '@material-ui/core';
import MenuFragment from './MenuFragment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));


export default function MenuListComposition(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const MenuText = withStyles((theme) => ({
        root: {
            color: "#707070",
            float: "right"
        }
    }))(Typography);

    const MenuButton = withStyles((theme) => ({
        root: {
            marginBottom: theme.spacing(0),
            float: 'right',
            [theme.breakpoints.down('sm')]: {
                borderBottom: "1px solid #CECACA",
                borderRadius: 0
            },
        }
    }))(Button);

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div>
                        <MenuButton {...bindTrigger(popupState)}>
                            <MenuText noWrap color="secondary" >
                                {props.name}
                            </MenuText>
                        </MenuButton>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuFragment parentPath={props.path} submenus={props.submenus} />
                        </Popover>
                    </div>
                )}
            </PopupState>
        </div>
    )
}
