import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    username: ''
  },
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
      state.username = action.payload;
    },
    logout(state, action) {
      state.isLoggedIn = false;
    },
  },
});

export const {login, logout} = userSlice.actions;

export default userSlice.reducer;
