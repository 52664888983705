import React from 'react'
import { Grid, Box, Typography, Card, CardMedia } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InstagramFlowSlider from '../../components/Sliders/InstagramFlowSlider';
import ImageBar from '../../components/Collections/ImageBar';
import Banner from '../../components/AppBar/Banner';
import Image from 'material-ui-image';
import CollectionBrowseButton from '../../components/Collections/CollectionBrowseButton';
import FourImage from '../../components/Collections/FourImage';
const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        textAlign: "center",
    },
    buttonBrowseTwoImageColumn: {
        marginBottom: "2em",
        marginTop: "2em",
    },
    Card: {
        backgroundColor: 'rgba(1,1,1,0)',
        borderRadius: 0,
        boxShadow: 'none',
        marginTop: "6em",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        marginBottom: "6em",
    },
    RingCard: {
        backgroundColor: '#fff',
        borderRadius: 0,
        boxShadow: 'none',
        marginTop: "6em",
        marginBottom: "2em",
    },
    media: {
        height: "40em",
        width: "40em",
        marginBottom: "2em"
    },
    RingMedia: {
        height: "20em",
        width: "20em",
        marginBottom: "2em"
    },
    FullSizeImageGrid: {
        marginTop: "2em",
        marginBottom: "2em",
    },
    SeperatorGrid: {
        marginBottom: "2em"
    },
    MediaAndCollectionsGrid: {
        marginBottom: "3em",
        marginTop: "5em",
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },

    },
    InstagramFlow: {
        marginTop: "5em",
        marginBottom: "5em",
        flexDirection: 'column'
    },
    TextAndButtonPadding: {
        paddingLeft: "3em",
        paddingRight: "3em",
    }
}));

const Cards = [
    {
        text: "Folksy Collection",
        image: "img/fcollect1.jpg",
        productId: "1"
    }, {
        text: "Folksy Collection",
        image: "img/fcollect2.jpg",
        productId: "2"
    },
    {
        text: "Folksy Collection",
        image: "img/fcollect3.jpg",
        productId: "3"
    },
    {
        text: "Folksy Collection",
        image: "img/fcollect4.jpg",
        productId: "4"
    },
]

const CollectionRings = [
    {
        text: "Folksy Collection",
        image: "img/ring1.jpg"
    }, {
        text: "Folksy Collection",
        image: "img/ring2.jpg"
    },
    {
        text: "Folksy Collection",
        image: "img/ring3.jpg"
    },
    {
        text: "Folksy Collection",
        image: "img/ring4.jpg"
    },
]

const TitleText = withStyles({
    root: {
        fontFamily: "Italianno"
    }
})(Typography);


function MainPage() {
    const classes = useStyles();
    return (
        <Grid className={classes.container} >
            <Banner />
            <Grid
                alignItems="center"
                justify="center"
                spacing={10}
            >
                <Grid className={classes.SeperatorGrid}>
                    <TitleText variant="h2"> Favorites Forever </TitleText>
                    <CollectionBrowseButton />
                </Grid>

                <Grid
                    alignItems="center"
                    justify="center">
                    <ImageBar data={Cards} />
                </Grid>

                <Grid className={classes.FullSizeImageGrid}>
                    <Image aspectRatio={1140 / 484}
                        src="img/model1.jpg" />
                </Grid>

                <Grid >
                    <TitleText variant="h2">
                        <Box letterSpacing={3}>
                            The lively collection in office life
                        </Box>
                    </TitleText>
                    <CollectionBrowseButton />
                </Grid>

                <Grid className={classes.FullSizeImageGrid}>
                    <ImageBar data={CollectionRings} />
                </Grid>


                <Grid className={classes.MediaAndCollectionsGrid}>
                    <Grid xs={12} >
                        <Grid className={classes.TextAndButtonPadding}>
                            <TitleText color="secondary" variant="h2">
                                For those who feel cheerful,
                                lively and full of life
                            </TitleText>
                            <CollectionBrowseButton />
                        </Grid>
                        <FourImage />
                    </Grid>

                    <Grid xs={12} >
                        <Image aspectRatio={561 / 818} src="img/model2.jpg" />
                    </Grid>
                </Grid>


                <Grid className={classes.InstagramFlow} >
                    <InstagramFlowSlider />
                </Grid>
            </Grid>

        </Grid>
    );
}

export default MainPage;

