import { createSlice } from '@reduxjs/toolkit';
import { MenuItems } from '../data/menuItems';
import { Collections } from '../data/collection';
import { AllJewellers } from '../data/alljewelry';
const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    header: 'SERKAN AHLAT',
    loading: false,
    collectionCard: false,
    Menu: MenuItems,
    Collections: Collections,
    AllJewellers: AllJewellers
  },
  reducers: {
    changeLayoutHeader(state, action) {
      state.header = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    collectionCardOnOff(state, action) {
      state.collectionCard = action.payload;
    },

  },
});

export const { changeLayoutHeader, setLoading, collectionCardOnOff } = layoutSlice.actions;

export default layoutSlice.reducer;
