import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import DashboardLayout from '../../components/MainLayout/MainLayout';
import Routes from './Routes';
import ReduxManagedSpinner from '../../components/ReduxManagedSpinner/ReduxManagedSpinner';
import Theme from '../../components/Theme/Theme';
import './App.css';
require('dotenv').config()
const App = () => {

  return (
    <>
      <Theme>
        <ReduxManagedSpinner />
        <Router>
          <Switch>

            <Route path="/">
              <DashboardLayout>
                <Routes />
              </DashboardLayout>
            </Route>
          </Switch>
        </Router>
      </Theme>
    </>
  );
}

export default App;
