
import React from 'react';
import Theme from '../Theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image'
import Carousel from 'react-multi-carousel';

const useStyles = makeStyles((theme) => ({
    InstagramGrid: {
        position: "relative",
    },
    Card: {
        backgroundColor: 'rgba(1,1,1,0)',
        borderRadius: 0,
        boxShadow: 'none',
        marginTop: "1.5em",
        marginBottom: "1em",
    },
    cardGrid: {
        marginBottom: "2em",
        padding: theme.spacing(1),
    },
}));

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const CardText = withStyles({
    root: {
        fontFamily: "Roboto",
        fontWeight: 'Regular',
        marginTop: "0.8em"
    }
})(Typography);

const ImageBar = (props) => {
    const classes = useStyles();
    return (
        <Carousel className={classes.InstagramGrid} responsive={responsive}>
            {
                props.data.map((card) =>
                    <>
                        <Image
                            aspectRatio={270 / 254}
                            cover={true}
                            className={classes.media}
                            src={card.image}
                        />
                        <CardText color="secondary" > {card.text} </CardText>
                    </>
                )
            }
        </Carousel>
    );
}
export default ImageBar;