import React from "react";
import { Switch, Route } from "react-router-dom";
import MainPage from '../Collections/MainPage';
import CollectionPage from '../Collections/CollectionPage';
import ProductDetailPage from '../Product/ProductDetailPage';
import Contact from '../AboutUs/Contact';
import Discover from '../AboutUs/Discover';
import Lookbok from '../AboutUs/Lookbook';
import List from '../AllJewellers/List';

const Routes = props => (
  <Switch>
    <Route exact path="/" component={MainPage} />
    <Route exact path="/Collections/:CollectionName" component={CollectionPage} />
    <Route exact path="/ProductDetail/:CollectionName/:ProductId"
      component={ProductDetailPage} />
    <Route exact path="/Discover" component={Discover} />
    <Route exact path="/Lookbook" component={Lookbok} />
    <Route exact path="/Contact" component={Contact} />
    <Route exact path="/AllJewellers/:JewellersName" component={List} />

  </Switch>
);
export default Routes;