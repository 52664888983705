import React from 'react'
import { Grid, Button, Typography, Card, CardMedia } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import './canva.css';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

const CardText = withStyles({
    root: {
        fontFamily: "Roboto",
    }
})(Typography);

export default function Lookbook() {
    const classes = useStyles();
    const script = document.createElement("script");
    script.src = "https://sdk.canva.com/v1/embed.js";
    script.async = true;

    return (
        <Grid
            className={classes.container}
        >
            <div class="canvaDiv">
                <iframe class="canvaFrame"
                    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAEZw2fmUHg&#x2F;view?embed">
                </iframe>
            </div>
        </Grid>
    )
}
