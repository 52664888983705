import React from 'react'
import { Grid, Button, Typography, Card, CardMedia } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import { useSelector } from 'react-redux';
import ReactImageZoom from 'react-image-zoom';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: 'column',
        marginBottom: theme.spacing(5),
    },
    firstSection: {
        display: "flex",
        flexDirection: 'row',

    },
    secondSection: {

    },
    rightImage: {
        marginBottom: theme.spacing(1),
    },
    leftImage: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    CardGrid: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    }

}));

const CardText = withStyles({
    root: {
        fontFamily: "Roboto",
    }
})(Typography);

export default function ProductDetailPage(props) {
    const classes = useStyles();

    const Collection = useSelector(state => state.layout).Collections[props.match.params.CollectionName];
    const Products = Collection.section1.concat(Collection.section2);
    const Product = Products.find((row) => row.ProductId == props.match.params.ProductId)
    return (
        <Grid
            className={classes.container}>
            <Grid className={classes.CardGrid}>
                <CardText color="secondary" variant="p"> {props.match.params.ProductId} </CardText>
            </Grid>
            <Grid className={classes.firstSection}>
                <Grid xs={6} className={classes.leftImage}>
                    <Image aspectRatio={1 / 1}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/${Product.Image}`} />

                </Grid>
                <Grid xs={6} className={classes.rightImage}>
                    <Image aspectRatio={1 / 1}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/${Product.Image2 == "" ? Product.Image : Product.Image2}`} />

                </Grid>
            </Grid>
            <Grid className={classes.secondSection}>

                <Image cover={true} aspectRatio={1176 / 580}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/${Product.Image}`} />


            </Grid>
        </Grid>
    )
}
