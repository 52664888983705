import React from 'react';

function Home() {
  return (
    <div className="Home">
    </div>
  );
}

export default Home;
