export const SLIDE_INFO = [
    { src: 'img/slider/1.jpg' },
    { src: 'img/slider/2.jpg' },
    { src: 'img/slider/3.jpg' },
    { src: 'img/slider/4.jpg' },
    { src: 'img/slider/5.jpg'},
    { src: 'img/slider/6.jpg'},
];

export const MOBILE_SLIDE_INFO = [
    { src: 'img/slider/m1.jpg' },
    { src: 'img/slider/m2.jpg' },
    { src: 'img/slider/m3.jpg' },
    { src: 'img/slider/m4.jpg' },
    { src: 'img/slider/m5.jpg'},
    { src: 'img/slider/m6.jpg'},
];