import React, { useState, useEffect } from 'react';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Grid, makeStyles } from '@material-ui/core';
import Image from 'material-ui-image'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { SLIDE_INFO, MOBILE_SLIDE_INFO } from '../../data/SlideInfo'

const useStyles = makeStyles(() => ({
    Carousel: {
        width: "100%",
        marginBottom: "1em"
    },
}));


function Banner() {
    const classes = useStyles();
    const [isMobile, setMobileView] = useState(false);

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setMobileView(true)
                : setMobileView(false);
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.App}>
            <Carousel showThumbs={false} autoPlay className={classes.Carousel}>
                {
                    isMobile ? MOBILE_SLIDE_INFO.map((slide) => {
                        return (
                            <div>
                                <Image className={classes.Image} aspectRatio={427 / 640} src={slide.src} />
                            </div>
                        )
                    }) :
                        SLIDE_INFO.map((slide) => {
                            return (
                                <div>
                                    <Image className={classes.Image} aspectRatio={1140 / 484} src={slide.src} />
                                </div>
                            )
                        })
                }

            </Carousel>
        </Grid>
    )
}

export default Banner;