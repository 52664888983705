import React from 'react'
import { Grid, Button, Typography, Card, CardMedia } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    banner: {
        marginBottom: theme.spacing(1),
    },
    section: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    discoverText: {
    
    }
}));

const TitleText = withStyles({
    root: {
        fontFamily: "Helvetica Neue",
    }
})(Typography);

export default function Discover() {
    const classes = useStyles();

    return (
        <Grid
            className={classes.container}
        >
            <Grid className={classes.banner} >
                <Image aspectRatio={1151 / 314} src={`${process.env.REACT_APP_PUBLIC_URL}/img/DiscoverBanner.jpg`} />
            </Grid>

            <Grid className={classes.section} container justify="center" alignItems="center" >
                <Grid md={1}>
                    <Image color="transparent" aspectRatio={164 / 140} src={`${process.env.REACT_APP_PUBLIC_URL}/img/BlackLogo.png`} />
                </Grid>

            </Grid>

            <Grid className={classes.section} >
                <TitleText align="center" className={classes.discoverText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />
                 incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. <br />
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
                <br /><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />
                 incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. <br />
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
                <br /><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />
                 incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. <br />
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
                <br /><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br />
                 incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. <br />
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
                <br /><br />
                </TitleText>
            </Grid>

        </Grid>
    )
}
