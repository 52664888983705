import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, withStyles, Typography } from '@material-ui/core';
import Image from 'material-ui-image';
import ImageBar from '../../components/Collections/ImageBar';
const useStyles = makeStyles((theme) => ({
    twoColumnImageGrid: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '2em',
    },
    quadranSizeGrid: {
        margin: "1em"
    },
}));



const CardText = withStyles({
    root: {
        fontFamily: "Roboto",
        fontWeight: 'Regular',
        marginTop: "0.8em"
    }
})(Typography);

var data = [
    {
        "image": "img/four1.jpg"
    },
    {
        "image": "img/four2.jpg"
    },
    {
        "image": "img/four3.jpg"
    },
    {
        "image": "img/four4.jpg"
    }
]

const mobileView = () => {
    return (<ImageBar data={data} />)
}

const desktopView = (classes) => {
    return (
        <Grid
            alignItems="center"
            justify="center"
            className={classes.twoColumnImageGrid}>
            <Grid xs={12}>
                <Grid className={classes.quadranSizeGrid} >
                    <Image src="img/four1.jpg" />
                    <CardText color="secondary" > Folksy Collection </CardText>
                </Grid>
                <Grid className={classes.quadranSizeGrid} >
                    <Image src="img/four2.jpg" />
                    <CardText color="secondary"> Folksy Collection </CardText>
                </Grid>
            </Grid>

            <Grid xs={12}>
                <Grid className={classes.quadranSizeGrid} >
                    <Image src="img/four3.jpg" />
                    <CardText color="secondary"> Folksy Collection </CardText>
                </Grid>
                <Grid className={classes.quadranSizeGrid} >
                    <Image src="img/four4.jpg" />
                    <CardText color="secondary"> Folksy Collection </CardText>
                </Grid>
            </Grid>

        </Grid>
    )
}


export default function FourImage() {
    const classes = useStyles();
    const [isMobileView, setMobileView] = useState(false);
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 960
                ? setMobileView(true)
                : setMobileView(false);
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);
    return (
        isMobileView ? mobileView(classes) :
            desktopView(classes)
    )
}
