import React from 'react'
import { Grid, Button, Typography, Card, CardMedia } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    banner: {
        marginBottom: theme.spacing(1),
    },
    section: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    discoverText: {
        
    }
}));

const TitleText = withStyles({
    root: {
        fontFamily: "Helvetica Neue",
    }
})(Typography);

export default function Contact() {
    const classes = useStyles();
    return (
        <Grid
            className={classes.container}
        >
            <Grid className={classes.banner} >
                <Image aspectRatio={1151 / 314}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/img/ContactBanner.jpg`} />
            </Grid>

            <Grid className={classes.section} container justify="center" alignItems="center" >
                <Grid md={1}>
                    <Image color="transparent" aspectRatio={164 / 140} src={`${process.env.REACT_APP_PUBLIC_URL}/img/BlackLogo.png`} />
                </Grid>

            </Grid>

            <Grid className={classes.section} >
                <TitleText  align="center" className={classes.discoverText}>
                    Kuyumcukent Wedding World Mall <br />
                    Firuze Street 1st Floor No:518 <br />
                    Yenibosna - İstanbul- TURKEY <br />
                    +90 212 603 20 13 (TR OFFICE)
            </TitleText>
            </Grid>

        </Grid>
    )
}
