import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from './MenuItem';
import MenuListComposition from './MenuListComposition';

const useStyles = makeStyles((theme) => ({
  horizontalListStyle: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  listItem: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
}));


const MenuButtons = () => {
  const classes = useStyles();
  const menuItems = useSelector(state => state.layout).Menu;

  return (
    <List className={classes.horizontalListStyle}>
      {

        menuItems.map((MenusItem) => {
          return (MenusItem.accordion ?
            <MenuListComposition className={classes.listItem} path={MenusItem.path} name={MenusItem.name}
              submenus={MenusItem.submenus} />
            : <MenuItem className={classes.listItem} name={MenusItem.name} path={MenusItem.path} />)
        })
      }
    </List>
  );
}
export default MenuButtons;