import React from 'react'
import { Typography, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    btnBrowse: {
        borderRadius: 0,
        border: '.01em solid',
        borderColor: '#707070',
        height:"2.2em"
    },
}));
export default function CollectionBrowseButton() {
    const classes = useStyles();

    const ButtonText = withStyles({
        root: {
            fontFamily: "Roboto",
            fontSize: '.6rem',
        }
    })(Typography);
    return (
        <Button variant="outlined" className={classes.btnBrowse}>
            <ButtonText noWarp >
                Browse Our Collections
            </ButtonText>
        </Button>
    )
}
