import React from 'react'
import { Grid, Typography, Button, Avatar } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'material-ui-image'
import CardMedia from '@material-ui/core/CardMedia';

import './Menu.css';

const useStyles = makeStyles((theme) => ({
    horizontalListStyle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            minWidth: "35vh"
        },
    },
    listItem: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    row: {
        borderTop: "1px solid #CECACA",
        borderRadius: 0,
        padding: 0,
        marginTop: theme.spacing(1),

        '&:first-child': {
            borderTop: 'none',
        }
    },
    firstSection: {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column'
        },
    },
    secondSection: {
        display: 'flex',
        flexDirection: 'row'
    },
    Images: {
        minWidth: "10vh",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    smallImage: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    }
}));

const MenuText = withStyles((theme) => ({
    root: {
        color: "#707070",
        float: "right",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem"
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: "1em"
        },


    }
}))(Typography);

const MenuButton = withStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(0),
        float: 'right',
        width: "100%",
    }
}))(Button);
export default function MenuFragment(props) {
    const classes = useStyles();
    return (
        <Grid md={12} className={classes.horizontalListStyle}>

            <Grid xs={12} md={3} className={classes.firstSection}>
                {
                    props.submenus.map(({ name, path, img }) => {

                        return (
                            <MenuButton className={classes.row} to={`/${props.parentPath}/${path}`} component={RouterLink} >
                                <Grid xs={9} md={12}>
                                    <MenuText nowarp={true} color="secondary"> {name} </MenuText>
                                </Grid>


                                <Hidden mdUp >
                                    <Grid xs={3}>
                                        <Avatar className={classes.smallImage} variant="square" src={`${process.env.REACT_APP_PUBLIC_URL}/${img}`} />
                                    </Grid>
                                </Hidden>
                            </MenuButton>
                        )
                    })
                }
            </Grid>
            <Hidden smDown >
                <Grid className={classes.secondSection} md={12}


                >
                    {props.submenus.map(({ name, path, BigImg }) => {
                        return (BigImg &&
                            <Grid md={4} spacing={5} className={classes.Images} button to={`/${props.parentPath}/${path}`} component={RouterLink}>
                                <Image aspectRatio={264 / 165} src={`${process.env.REACT_APP_PUBLIC_URL}/${BigImg}`} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Hidden>
        </Grid>
    )
}
