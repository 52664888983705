import React, { useState, useEffect } from 'react'
import { Grid, Box, IconButton, Button, AppBar, Toolbar, Drawer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import MenuButtons from '../Menu/MenuButtons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'hidden',
        boxShadow: 'none',
        background: '#F7F7F7',
        position: "static",
        [theme.breakpoints.down('sm')]: {
            position: "fixed",
            background: 'transparent',

        },
    },
    menuButton: {
        display: 'none',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        },
    },
    header: {
        marginLeft: "1em",
        backgroundColor: 'transparent',
    },
    scrolledDownAppBar: {
        background: 'rgba(86, 101, 115, 0.4)',
        position: "fixed"
    },
    menuIcon: {
        float: "right"
    },
}));

const getDrawerChoices = () => {
    return (<MenuButtons />);

};

const LogoText = withStyles((theme) => ({
    root: {
        color: "#707070",
        [theme.breakpoints.down('sm')]: {
            color: "#FFFFFF",
        },
    }
}))(Typography);

function AppBarFullSizeImage(props) {

    const classes = useStyles();
    const layoutStore = useSelector(state => state.layout);
    const [open, setOpen] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const [scrolledDown, setScrolledDown] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setDrawerOpen(true);
        const handleDrawerClose = () =>
            setDrawerOpen(false);
        return (
            <Toolbar className={classes.header}>
                <Grid xs={9} >
                    <Button to={`/`} component={RouterLink}>
                        <Box color="#707070" fontWeight="medium" letterSpacing={2} m={1}>
                            <LogoText >
                                {layoutStore.header}
                            </LogoText>
                        </Box>
                    </Button>

                </Grid>

                <Grid xs={3} alignContent="flex-end">
                    <IconButton
                        {...{
                            edge: "end",
                            "aria-label": "menu",
                            "aria-haspopup": "true",
                            onClick: handleDrawerOpen,
                        }} className={classes.menuIcon}
                    >
                        <MenuIcon color="primary" />
                    </IconButton>
                </Grid>

                <Drawer
                    {...{
                        anchor: "right",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <IconButton
                        {...{
                            edge: "end",
                            color: "textPrimary",
                            "aria-label": "menu",
                            "aria-haspopup": "true",
                            onClick: handleDrawerClose,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <div>{getDrawerChoices()}</div>
                </Drawer>
            </Toolbar>
        );
    };

    const displayDesktop = () => {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center">
                <Button to={`/`} component={RouterLink}>
                    <Typography color="secondary" variant="h5" noWrap >
                        <Box color="textPrimary.main" letterSpacing={3} m={1} >
                            {layoutStore.header}
                        </Box>
                    </Typography>
                </Button>

                <MenuButtons />
            </Grid>
        )
    }
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setMobileView(true)
                : setMobileView(false);

        };
        const handleScroll = () => {
            console.log("scroll")
            window.scrollY > 10 ? setScrolledDown(true) : setScrolledDown(false);
        };

        setResponsiveness();
        handleScroll();
        window.addEventListener("resize", () => setResponsiveness());
        mobileView && window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrolledDown, mobileView]);


    return (
        <AppBar className={clsx({
            [classes.appBar]: true,
            [classes.scrolledDownAppBar]: scrolledDown
        })}
        >
            {
                mobileView ? displayMobile() :
                    displayDesktop()
            }
        </AppBar >

    )
}

export default AppBarFullSizeImage
