export const Collections =
{
    StonexCollection: {
        banner: "img/StonexCollection/banner.jpg",
        title: "Stonex Collection",
        section1: [
            { Image: "img/StonexCollection/Stonex0101.jpg", Image2: "img/StonexCollection/Stonex0102.jpg", Image3: "img/StonexCollection/Stonex0103.jpg", name: "Stonex 1", ProductId: "Stonex01" },
            { Image: "img/StonexCollection/Stonex0201.jpg", Image2: "img/StonexCollection/Stonex0202.jpg", Image3: "img/StonexCollection/Stonex0203.jpg", name: "Stonex 2", ProductId: "Stonex02" },
            { Image: "img/StonexCollection/Stonex0301.jpg", Image2: "img/StonexCollection/Stonex0302.jpg", Image3: "img/StonexCollection/Stonex0303.jpg", name: "Stonex 3", ProductId: "Stonex03" },
            { Image: "img/StonexCollection/Stonex0401.jpg", Image2: "img/StonexCollection/Stonex0402.jpg", Image3: "img/StonexCollection/Stonex0403.jpg", name: "Stonex 4", ProductId: "Stonex04" },
            { Image: "img/StonexCollection/Stonex0501.jpg", Image2: "img/StonexCollection/Stonex0502.jpg", Image3: "img/StonexCollection/Stonex0503.jpg", name: "Stonex 5", ProductId: "Stonex05" },
            { Image: "img/StonexCollection/Stonex0601.jpg", Image2: "img/StonexCollection/Stonex0602.jpg", Image3: "img/StonexCollection/Stonex0603.jpg", name: "Stonex 6", ProductId: "Stonex06" },
            { Image: "img/StonexCollection/Stonex0701.jpg", Image2: "img/StonexCollection/Stonex0702.jpg", Image3: "img/StonexCollection/Stonex0703.jpg", name: "Stonex 7", ProductId: "Stonex07" },
            { Image: "img/StonexCollection/Stonex0801.jpg", Image2: "img/StonexCollection/Stonex0802.jpg", Image3: "img/StonexCollection/Stonex0803.jpg", name: "Stonex 8", ProductId: "Stonex08" },
            { Image: "img/StonexCollection/Stonex0901.jpg", Image2: "img/StonexCollection/Stonex0902.jpg", Image3: "img/StonexCollection/Stonex0903.jpg", name: "Stonex 9", ProductId: "Stonex09" },
            { Image: "img/StonexCollection/Stonex1001.jpg", Image2: "img/StonexCollection/Stonex1002.jpg", Image3: "img/StonexCollection/Stonex1003.jpg", name: "Stonex 10", ProductId: "Stonex10" },
            { Image: "img/StonexCollection/Stonex1101.jpg", Image2: "img/StonexCollection/Stonex1102.jpg", Image3: "img/StonexCollection/Stonex1103.jpg", name: "Stonex 11", ProductId: "Stonex11" },
            { Image: "img/StonexCollection/Stonex1201.jpg", Image2: "img/StonexCollection/Stonex1202.jpg", Image3: "img/StonexCollection/Stonex1203.jpg", name: "Stonex 12", ProductId: "Stonex12" },
        ],
        section2: [
            { Image: "img/StonexCollection/Stonex1301.jpg", Image2: "img/StonexCollection/Stonex1302.jpg", Image3: "img/StonexCollection/Stonex1303.jpg", name: "Stonex 13", ProductId: "Stonex13" },
            { Image: "img/StonexCollection/Stonex1401.jpg", Image2: "img/StonexCollection/Stonex1402.jpg", Image3: "img/StonexCollection/Stonex1403.jpg", name: "Stonex 14", ProductId: "Stonex14" },
            { Image: "img/StonexCollection/Stonex1501.jpg", Image2: "img/StonexCollection/Stonex1502.jpg", Image3: "img/StonexCollection/Stonex1503.jpg", name: "Stonex 15", ProductId: "Stonex15" },
            { Image: "img/StonexCollection/Stonex1601.jpg", Image2: "img/StonexCollection/Stonex1602.jpg", Image3: "img/StonexCollection/Stonex1603.jpg", name: "Stonex 16", ProductId: "Stonex16" },
            { Image: "img/StonexCollection/Stonex1701.jpg", Image2: "img/StonexCollection/Stonex1702.jpg", Image3: "img/StonexCollection/Stonex1703.jpg", name: "Stonex 17", ProductId: "Stonex17" },
            { Image: "img/StonexCollection/Stonex1801.jpg", Image2: "img/StonexCollection/Stonex1802.jpg", Image3: "img/StonexCollection/Stonex1803.jpg", name: "Stonex 18", ProductId: "Stonex18" },
            { Image: "img/StonexCollection/Stonex1901.jpg", Image2: "img/StonexCollection/Stonex1902.jpg", Image3: "img/StonexCollection/Stonex1903.jpg", name: "Stonex 19", ProductId: "Stonex19" },
            { Image: "img/StonexCollection/Stonex2001.jpg", Image2: "img/StonexCollection/Stonex2002.jpg", Image3: "img/StonexCollection/Stonex2003.jpg", name: "Stonex 20", ProductId: "Stonex20" },
            { Image: "img/StonexCollection/Stonex2101.jpg", Image2: "img/StonexCollection/Stonex2102.jpg", Image3: "img/StonexCollection/Stonex2103.jpg", name: "Stonex 21", ProductId: "Stonex21" },
            { Image: "img/StonexCollection/Stonex2201.jpg", Image2: "img/StonexCollection/Stonex2202.jpg", Image3: "img/StonexCollection/Stonex2203.jpg", name: "Stonex 22", ProductId: "Stonex22" },
            { Image: "img/StonexCollection/Stonex2301.jpg", Image2: "img/StonexCollection/Stonex2302.jpg", Image3: "img/StonexCollection/Stonex2303.jpg", name: "Stonex 23", ProductId: "Stonex23" },
            { Image: "img/StonexCollection/Stonex2401.jpg", Image2: "img/StonexCollection/Stonex2402.jpg", Image3: "img/StonexCollection/Stonex2403.jpg", name: "Stonex 24", ProductId: "Stonex24" },
            { Image: "img/StonexCollection/Stonex2501.jpg", Image2: "img/StonexCollection/Stonex2502.jpg", Image3: "img/StonexCollection/Stonex2503.jpg", name: "Stonex 25", ProductId: "Stonex25" },
            { Image: "img/StonexCollection/Stonex2601.jpg", Image2: "img/StonexCollection/Stonex2602.jpg", Image3: "img/StonexCollection/Stonex2603.jpg", name: "Stonex 26", ProductId: "Stonex26" },
            { Image: "img/StonexCollection/Stonex2701.jpg", Image2: "img/StonexCollection/Stonex2702.jpg", Image3: "img/StonexCollection/Stonex2703.jpg", name: "Stonex 27", ProductId: "Stonex27" },
            { Image: "img/StonexCollection/Stonex2801.jpg", Image2: "img/StonexCollection/Stonex2802.jpg", Image3: "img/StonexCollection/Stonex2803.jpg", name: "Stonex 28", ProductId: "Stonex28" },
            { Image: "img/StonexCollection/Stonex2901.jpg", Image2: "img/StonexCollection/Stonex2902.jpg", Image3: "img/StonexCollection/Stonex2903.jpg", name: "Stonex 29", ProductId: "Stonex29" },
            { Image: "img/StonexCollection/Stonex3001.jpg", Image2: "img/StonexCollection/Stonex3002.jpg", Image3: "img/StonexCollection/Stonex3003.jpg", name: "Stonex 30", ProductId: "Stonex30" },
            { Image: "img/StonexCollection/Stonex3101.jpg", Image2: "img/StonexCollection/Stonex3102.jpg", Image3: "img/StonexCollection/Stonex3103.jpg", name: "Stonex 31", ProductId: "Stonex31" },
            { Image: "img/StonexCollection/Stonex3201.jpg", Image2: "img/StonexCollection/Stonex3202.jpg", Image3: "img/StonexCollection/Stonex3203.jpg", name: "Stonex 32", ProductId: "Stonex32" },
            { Image: "img/StonexCollection/Stonex3301.jpg", Image2: "img/StonexCollection/Stonex3302.jpg", Image3: "img/StonexCollection/Stonex3303.jpg", name: "Stonex 33", ProductId: "Stonex33" },
            { Image: "img/StonexCollection/Stonex3401.jpg", Image2: "img/StonexCollection/Stonex3402.jpg", Image3: "img/StonexCollection/Stonex3403.jpg", name: "Stonex 34", ProductId: "Stonex34" },
            { Image: "img/StonexCollection/Stonex3501.jpg", Image2: "img/StonexCollection/Stonex3502.jpg", Image3: "img/StonexCollection/Stonex3503.jpg", name: "Stonex 35", ProductId: "Stonex35" },
            { Image: "img/StonexCollection/Stonex3601.jpg", Image2: "img/StonexCollection/Stonex3602.jpg", Image3: "img/StonexCollection/Stonex3603.jpg", name: "Stonex 36", ProductId: "Stonex36" },
            { Image: "img/StonexCollection/Stonex3701.jpg", Image2: "img/StonexCollection/Stonex3702.jpg", Image3: "img/StonexCollection/Stonex3703.jpg", name: "Stonex 37", ProductId: "Stonex37" },
            { Image: "img/StonexCollection/Stonex3801.jpg", Image2: "img/StonexCollection/Stonex3802.jpg", Image3: "img/StonexCollection/Stonex3803.jpg", name: "Stonex 38", ProductId: "Stonex38" },
            { Image: "img/StonexCollection/Stonex3901.jpg", Image2: "img/StonexCollection/Stonex3902.jpg", Image3: "img/StonexCollection/Stonex3903.jpg", name: "Stonex 39", ProductId: "Stonex39" },

        ],
        modelImage1: "img/StonexCollection/modelImage1.jpg",
        presentation: "img/StonexCollection/presentationModel.jpg"
    },

    MegaCollection: {
        banner: "",
        title: "Mega Collection",
        section1: [
            { Image: "img/MegaCollection/Mega0101.jpg", Image2: "img/MegaCollection/Mega0102.jpg", Image3: "img/MegaCollection/Mega0103.jpg", name: "Mega 1", ProductId: "Mega01" },
            { Image: "img/MegaCollection/Mega0101.jpg", Image2: "img/MegaCollection/Mega0202.jpg", Image3: "img/MegaCollection/Mega0203.jpg", name: "Mega 2", ProductId: "Mega02" },
            { Image: "img/MegaCollection/Mega0301.jpg", Image2: "img/MegaCollection/Mega0302.jpg", Image3: "img/MegaCollection/Mega0303.jpg", name: "Mega 3", ProductId: "Mega03" },
            { Image: "img/MegaCollection/Mega0401.jpg", Image2: "img/MegaCollection/Mega0402.jpg", Image3: "img/MegaCollection/Mega0403.jpg", name: "Mega 4", ProductId: "Mega04" },
            { Image: "img/MegaCollection/Mega0501.jpg", Image2: "img/MegaCollection/Mega0502.jpg", Image3: "img/MegaCollection/Mega0503.jpg", name: "Mega 5", ProductId: "Mega05" },
            { Image: "img/MegaCollection/Mega0601.jpg", Image2: "img/MegaCollection/Mega0602.jpg", Image3: "img/MegaCollection/Mega0603.jpg", name: "Mega 6", ProductId: "Mega06" },
            { Image: "img/MegaCollection/Mega0701.jpg", Image2: "img/MegaCollection/Mega0702.jpg", Image3: "img/MegaCollection/Mega0703.jpg", name: "Mega 7", ProductId: "Mega07" },
            { Image: "img/MegaCollection/Mega0801.jpg", Image2: "img/MegaCollection/Mega0802.jpg", Image3: "img/MegaCollection/Mega0803.jpg", name: "Mega 8", ProductId: "Mega08" },
            { Image: "img/MegaCollection/Mega0901.jpg", Image2: "img/MegaCollection/Mega0902.jpg", Image3: "img/MegaCollection/Mega0903.jpg", name: "Mega 9", ProductId: "Mega09" },
            { Image: "img/MegaCollection/Mega1001.jpg", Image2: "img/MegaCollection/Mega1002.jpg", Image3: "img/MegaCollection/Mega1003.jpg", name: "Mega 10", ProductId: "Mega10" },
            { Image: "img/MegaCollection/Mega1101.jpg", Image2: "img/MegaCollection/Mega1102.jpg", Image3: "img/MegaCollection/Mega1103.jpg", name: "Mega 11", ProductId: "Mega11" },
            { Image: "img/MegaCollection/Mega1201.jpg", Image2: "img/MegaCollection/Mega1202.jpg", Image3: "img/MegaCollection/Mega1203.jpg", name: "Mega 12", ProductId: "Mega12" },
        ],
        section2: [
            { Image: "img/MegaCollection/Mega1301.jpg", Image2: "img/MegaCollection/Mega1302.jpg", Image3: "img/MegaCollection/Mega1303.jpg", name: "Mega 13", ProductId: "Mega13" },
            { Image: "img/MegaCollection/Mega1401.jpg", Image2: "img/MegaCollection/Mega1402.jpg", Image3: "img/MegaCollection/Mega1403.jpg", name: "Mega 14", ProductId: "Mega14" },
            { Image: "img/MegaCollection/Mega1501.jpg", Image2: "img/MegaCollection/Mega1502.jpg", Image3: "img/MegaCollection/Mega1503.jpg", name: "Mega 15", ProductId: "Mega15" },
            { Image: "img/MegaCollection/Mega1601.jpg", Image2: "img/MegaCollection/Mega1602.jpg", Image3: "img/MegaCollection/Mega1603.jpg", name: "Mega 16", ProductId: "Mega16" },
            { Image: "img/MegaCollection/Mega1701.jpg", Image2: "img/MegaCollection/Mega1702.jpg", Image3: "img/MegaCollection/Mega1703.jpg", name: "Mega 17", ProductId: "Mega17" },
            { Image: "img/MegaCollection/Mega1801.jpg", Image2: "img/MegaCollection/Mega1802.jpg", Image3: "img/MegaCollection/Mega1803.jpg", name: "Mega 18", ProductId: "Mega18" },
            { Image: "img/MegaCollection/Mega1901.jpg", Image2: "img/MegaCollection/Mega1902.jpg", Image3: "img/MegaCollection/Mega1903.jpg", name: "Mega 19", ProductId: "Mega19" },
            { Image: "img/MegaCollection/Mega2001.jpg", Image2: "img/MegaCollection/Mega2002.jpg", Image3: "img/MegaCollection/Mega2003.jpg", name: "Mega 20", ProductId: "Mega20" },
            { Image: "img/MegaCollection/Mega2101.jpg", Image2: "img/MegaCollection/Mega2102.jpg", Image3: "img/MegaCollection/Mega2103.jpg", name: "Mega 21", ProductId: "Mega21" },
            { Image: "img/MegaCollection/Mega2201.jpg", Image2: "img/MegaCollection/Mega2202.jpg", Image3: "img/MegaCollection/Mega2203.jpg", name: "Mega 22", ProductId: "Mega22" },
            { Image: "img/MegaCollection/Mega2301.jpg", Image2: "img/MegaCollection/Mega2302.jpg", Image3: "img/MegaCollection/Mega2303.jpg", name: "Mega 23", ProductId: "Mega23" },
            { Image: "img/MegaCollection/Mega2401.jpg", Image2: "img/MegaCollection/Mega2402.jpg", Image3: "img/MegaCollection/Mega2403.jpg", name: "Mega 24", ProductId: "Mega24" },
            { Image: "img/MegaCollection/Mega2501.jpg", Image2: "img/MegaCollection/Mega2502.jpg", Image3: "img/MegaCollection/Mega2503.jpg", name: "Mega 25", ProductId: "Mega25" },
            { Image: "img/MegaCollection/Mega2601.jpg", Image2: "img/MegaCollection/Mega2602.jpg", Image3: "img/MegaCollection/Mega2603.jpg", name: "Mega 26", ProductId: "Mega26" },
            { Image: "img/MegaCollection/Mega2701.jpg", Image2: "img/MegaCollection/Mega2702.jpg", Image3: "img/MegaCollection/Mega2703.jpg", name: "Mega 27", ProductId: "Mega27" },
            { Image: "img/MegaCollection/Mega2801.jpg", Image2: "img/MegaCollection/Mega2802.jpg", Image3: "img/MegaCollection/Mega2803.jpg", name: "Mega 28", ProductId: "Mega28" },
            { Image: "img/MegaCollection/Mega2901.jpg", Image2: "img/MegaCollection/Mega2902.jpg", Image3: "img/MegaCollection/Mega2903.jpg", name: "Mega 29", ProductId: "Mega29" },
            { Image: "img/MegaCollection/Mega3001.jpg", Image2: "img/MegaCollection/Mega3002.jpg", Image3: "img/MegaCollection/Mega3003.jpg", name: "Mega 30", ProductId: "Mega30" },
            { Image: "img/MegaCollection/Mega3101.jpg", Image2: "img/MegaCollection/Mega3102.jpg", Image3: "img/MegaCollection/Mega3103.jpg", name: "Mega 31", ProductId: "Mega31" },
            { Image: "img/MegaCollection/Mega3201.jpg", Image2: "img/MegaCollection/Mega3202.jpg", Image3: "img/MegaCollection/Mega3203.jpg", name: "Mega 32", ProductId: "Mega32" },
            { Image: "img/MegaCollection/Mega3301.jpg", Image2: "img/MegaCollection/Mega3302.jpg", Image3: "img/MegaCollection/Mega3303.jpg", name: "Mega 33", ProductId: "Mega33" },
            { Image: "img/MegaCollection/Mega3401.jpg", Image2: "img/MegaCollection/Mega3402.jpg", Image3: "img/MegaCollection/Mega3403.jpg", name: "Mega 34", ProductId: "Mega34" },
            { Image: "img/MegaCollection/Mega3501.jpg", Image2: "img/MegaCollection/Mega3502.jpg", Image3: "img/MegaCollection/Mega3503.jpg", name: "Mega 35", ProductId: "Mega35" },
            { Image: "img/MegaCollection/Mega3601.jpg", Image2: "img/MegaCollection/Mega3602.jpg", Image3: "img/MegaCollection/Mega3603.jpg", name: "Mega 36", ProductId: "Mega36" },
            { Image: "img/MegaCollection/Mega3701.jpg", Image2: "img/MegaCollection/Mega3702.jpg", Image3: "img/MegaCollection/Mega3703.jpg", name: "Mega 37", ProductId: "Mega37" },
            { Image: "img/MegaCollection/Mega3801.jpg", Image2: "img/MegaCollection/Mega3802.jpg", Image3: "img/MegaCollection/Mega3803.jpg", name: "Mega 38", ProductId: "Mega38" },
            { Image: "img/MegaCollection/Mega3901.jpg", Image2: "img/MegaCollection/Mega3902.jpg", Image3: "img/MegaCollection/Mega3903.jpg", name: "Mega 39", ProductId: "Mega39" },
            { Image: "img/MegaCollection/Mega4001.jpg", Image2: "img/MegaCollection/Mega4002.jpg", Image3: "img/MegaCollection/Mega4003.jpg", name: "Mega 40", ProductId: "Mega40" },
            { Image: "img/MegaCollection/Mega4101.jpg", Image2: "img/MegaCollection/Mega4102.jpg", Image3: "img/MegaCollection/Mega4103.jpg", name: "Mega 41", ProductId: "Mega41" },
            { Image: "img/MegaCollection/Mega4201.jpg", Image2: "img/MegaCollection/Mega4202.jpg", Image3: "img/MegaCollection/Mega4203.jpg", name: "Mega 42", ProductId: "Mega42" },
        ],
        modelImage1: "",
        presentation: ""
    },
    HuggiesCollection: {
        banner: "",
        title: "Huggies Collection",
        section1: [
            { Image: "img/HuggiesCollection/Huggies0101.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0103.jpg", name: "Huggies 1", ProductId: "Huggies01" },
            { Image: "img/HuggiesCollection/Huggies0101.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0203.jpg", name: "Huggies 2", ProductId: "Huggies02" },
            { Image: "img/HuggiesCollection/Huggies0301.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0303.jpg", name: "Huggies 3", ProductId: "Huggies03" },
            { Image: "img/HuggiesCollection/Huggies0401.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0403.jpg", name: "Huggies 4", ProductId: "Huggies04" },
            { Image: "img/HuggiesCollection/Huggies0501.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0503.jpg", name: "Huggies 5", ProductId: "Huggies05" },
            { Image: "img/HuggiesCollection/Huggies0601.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0603.jpg", name: "Huggies 6", ProductId: "Huggies06" },
            { Image: "img/HuggiesCollection/Huggies0701.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0703.jpg", name: "Huggies 7", ProductId: "Huggies07" },
            { Image: "img/HuggiesCollection/Huggies0801.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0803.jpg", name: "Huggies 8", ProductId: "Huggies08" },
            { Image: "img/HuggiesCollection/Huggies0901.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies0903.jpg", name: "Huggies 9", ProductId: "Huggies09" },
            { Image: "img/HuggiesCollection/Huggies1001.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1003.jpg", name: "Huggies 10", ProductId: "Huggies10" },
            { Image: "img/HuggiesCollection/Huggies1101.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1103.jpg", name: "Huggies 11", ProductId: "Huggies11" },
            { Image: "img/HuggiesCollection/Huggies1201.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1203.jpg", name: "Huggies 12", ProductId: "Huggies12" },
        ],
        section2: [
            { Image: "img/HuggiesCollection/Huggies1301.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1303.jpg", name: "Huggies 13", ProductId: "Huggies13" },
            { Image: "img/HuggiesCollection/Huggies1401.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1403.jpg", name: "Huggies 14", ProductId: "Huggies14" },
            { Image: "img/HuggiesCollection/Huggies1501.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1503.jpg", name: "Huggies 15", ProductId: "Huggies15" },
            { Image: "img/HuggiesCollection/Huggies1601.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1603.jpg", name: "Huggies 16", ProductId: "Huggies16" },
            { Image: "img/HuggiesCollection/Huggies1701.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1703.jpg", name: "Huggies 17", ProductId: "Huggies17" },
            { Image: "img/HuggiesCollection/Huggies1801.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1803.jpg", name: "Huggies 18", ProductId: "Huggies18" },
            { Image: "img/HuggiesCollection/Huggies1901.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies1903.jpg", name: "Huggies 19", ProductId: "Huggies19" },
            { Image: "img/HuggiesCollection/Huggies2001.jpg", Image2: "", Image3: "img/HuggiesCollection/Huggies2003.jpg", name: "Huggies 20", ProductId: "Huggies20" },
        ],
        modelImage1: "",
        presentation: ""
    },

    NesoCollection: {
        banner: "",
        title: "Neso Collection",
        section1: [
            { Image: "img/NesoCollection/Neso0101.jpg", Image2: "", Image3: "img/NesoCollection/Neso0103.jpg", name: "Neso 1", ProductId: "Neso01" },
            { Image: "img/NesoCollection/Neso0101.jpg", Image2: "", Image3: "img/NesoCollection/Neso0203.jpg", name: "Neso 2", ProductId: "Neso02" },
            { Image: "img/NesoCollection/Neso0301.jpg", Image2: "", Image3: "img/NesoCollection/Neso0303.jpg", name: "Neso 3", ProductId: "Neso03" },
            { Image: "img/NesoCollection/Neso0401.jpg", Image2: "", Image3: "img/NesoCollection/Neso0403.jpg", name: "Neso 4", ProductId: "Neso04" },
            { Image: "img/NesoCollection/Neso0501.jpg", Image2: "", Image3: "img/NesoCollection/Neso0503.jpg", name: "Neso 5", ProductId: "Neso05" },
            { Image: "img/NesoCollection/Neso0601.jpg", Image2: "", Image3: "img/NesoCollection/Neso0603.jpg", name: "Neso 6", ProductId: "Neso06" },
            { Image: "img/NesoCollection/Neso0701.jpg", Image2: "", Image3: "img/NesoCollection/Neso0703.jpg", name: "Neso 7", ProductId: "Neso07" },
            { Image: "img/NesoCollection/Neso0801.jpg", Image2: "", Image3: "img/NesoCollection/Neso0803.jpg", name: "Neso 8", ProductId: "Neso08" },
            { Image: "img/NesoCollection/Neso0901.jpg", Image2: "", Image3: "img/NesoCollection/Neso0903.jpg", name: "Neso 9", ProductId: "Neso09" },
            { Image: "img/NesoCollection/Neso1001.jpg", Image2: "", Image3: "img/NesoCollection/Neso1003.jpg", name: "Neso 10", ProductId: "Neso10" },
            { Image: "img/NesoCollection/Neso1101.jpg", Image2: "", Image3: "img/NesoCollection/Neso1103.jpg", name: "Neso 11", ProductId: "Neso11" },
            { Image: "img/NesoCollection/Neso1201.jpg", Image2: "", Image3: "img/NesoCollection/Neso1203.jpg", name: "Neso 12", ProductId: "Neso12" },
        ],
        section2: [
            { Image: "img/NesoCollection/Neso1301.jpg", Image2: "", Image3: "img/NesoCollection/Neso1303.jpg", name: "Neso 13", ProductId: "Neso13" },
            { Image: "img/NesoCollection/Neso1401.jpg", Image2: "", Image3: "img/NesoCollection/Neso1403.jpg", name: "Neso 14", ProductId: "Neso14" },
            { Image: "img/NesoCollection/Neso1501.jpg", Image2: "", Image3: "img/NesoCollection/Neso1503.jpg", name: "Neso 15", ProductId: "Neso15" },
            { Image: "img/NesoCollection/Neso1601.jpg", Image2: "", Image3: "img/NesoCollection/Neso1603.jpg", name: "Neso 16", ProductId: "Neso16" },
            { Image: "img/NesoCollection/Neso1701.jpg", Image2: "", Image3: "img/NesoCollection/Neso1703.jpg", name: "Neso 17", ProductId: "Neso17" },
            { Image: "img/NesoCollection/Neso1801.jpg", Image2: "", Image3: "img/NesoCollection/Neso1803.jpg", name: "Neso 18", ProductId: "Neso18" },
            { Image: "img/NesoCollection/Neso1901.jpg", Image2: "", Image3: "img/NesoCollection/Neso2003.jpg", name: "Neso 19", ProductId: "Neso19" },
            { Image: "img/NesoCollection/Neso2001.jpg", Image2: "", Image3: "img/NesoCollection/Neso2103.jpg", name: "Neso 20", ProductId: "Neso20" },
            { Image: "img/NesoCollection/Neso2101.jpg", Image2: "", Image3: "img/NesoCollection/Neso2203.jpg", name: "Neso 21", ProductId: "Neso21" },
            { Image: "img/NesoCollection/Neso2201.jpg", Image2: "", Image3: "img/NesoCollection/Neso2303.jpg", name: "Neso 22", ProductId: "Neso22" },
            { Image: "img/NesoCollection/Neso2301.jpg", Image2: "", Image3: "img/NesoCollection/Neso2403.jpg", name: "Neso 23", ProductId: "Neso23" },
            { Image: "img/NesoCollection/Neso2401.jpg", Image2: "", Image3: "img/NesoCollection/Neso2503.jpg", name: "Neso 24", ProductId: "Neso24" },
            { Image: "img/NesoCollection/Neso2501.jpg", Image2: "", Image3: "img/NesoCollection/Neso2603.jpg", name: "Neso 25", ProductId: "Neso25" },
            { Image: "img/NesoCollection/Neso2601.jpg", Image2: "", Image3: "img/NesoCollection/Neso2703.jpg", name: "Neso 26", ProductId: "Neso26" },
            { Image: "img/NesoCollection/Neso2701.jpg", Image2: "", Image3: "img/NesoCollection/Neso2803.jpg", name: "Neso 27", ProductId: "Neso27" },
            { Image: "img/NesoCollection/Neso2801.jpg", Image2: "", Image3: "img/NesoCollection/Neso2903.jpg", name: "Neso 28", ProductId: "Neso28" },
            { Image: "img/NesoCollection/Neso2901.jpg", Image2: "", Image3: "img/NesoCollection/Neso3003.jpg", name: "Neso 29", ProductId: "Neso29" },
        ],
        modelImage1: "",
        presentation: ""
    },

    OpalSeries: {
        banner: "img/StonexCollection/banner.jpg",
        title: "Opal Series",
        section1: [
            { Image: "img/OpalSeries/Opal0101.jpg", Image2: "img/OpalSeries/Opal0102.jpg", Image3: "img/OpalSeries/Opal0103.jpg", name: "Opal 1", ProductId: "Opal01" },
            { Image: "img/OpalSeries/Opal0101.jpg", Image2: "img/OpalSeries/Opal0202.jpg", Image3: "img/OpalSeries/Opal0203.jpg", name: "Opal 2", ProductId: "Opal02" },
            { Image: "img/OpalSeries/Opal0301.jpg", Image2: "img/OpalSeries/Opal0302.jpg", Image3: "img/OpalSeries/Opal0303.jpg", name: "Opal 3", ProductId: "Opal03" },
            { Image: "img/OpalSeries/Opal0401.jpg", Image2: "img/OpalSeries/Opal0402.jpg", Image3: "img/OpalSeries/Opal0403.jpg", name: "Opal 4", ProductId: "Opal04" },
            { Image: "img/OpalSeries/Opal0501.jpg", Image2: "img/OpalSeries/Opal0502.jpg", Image3: "img/OpalSeries/Opal0503.jpg", name: "Opal 5", ProductId: "Opal05" },
            { Image: "img/OpalSeries/Opal0601.jpg", Image2: "img/OpalSeries/Opal0602.jpg", Image3: "img/OpalSeries/Opal0603.jpg", name: "Opal 6", ProductId: "Opal06" },
            { Image: "img/OpalSeries/Opal0701.jpg", Image2: "img/OpalSeries/Opal0702.jpg", Image3: "img/OpalSeries/Opal0703.jpg", name: "Opal 7", ProductId: "Opal07" },
            { Image: "img/OpalSeries/Opal0801.jpg", Image2: "img/OpalSeries/Opal0802.jpg", Image3: "img/OpalSeries/Opal0803.jpg", name: "Opal 8", ProductId: "Opal08" },
            { Image: "img/OpalSeries/Opal0901.jpg", Image2: "img/OpalSeries/Opal0902.jpg", Image3: "img/OpalSeries/Opal0903.jpg", name: "Opal 9", ProductId: "Opal09" },
            { Image: "img/OpalSeries/Opal1001.jpg", Image2: "img/OpalSeries/Opal1002.jpg", Image3: "img/OpalSeries/Opal1003.jpg", name: "Opal 10", ProductId: "Opal10" },
            { Image: "img/OpalSeries/Opal1101.jpg", Image2: "img/OpalSeries/Opal1102.jpg", Image3: "img/OpalSeries/Opal1103.jpg", name: "Opal 11", ProductId: "Opal11" },
            { Image: "img/OpalSeries/Opal1201.jpg", Image2: "img/OpalSeries/Opal1202.jpg", Image3: "img/OpalSeries/Opal1203.jpg", name: "Opal 12", ProductId: "Opal12" },
        ],
        section2: [
            { Image: "img/OpalSeries/Opal1301.jpg", Image2: "img/OpalSeries/Opal1302.jpg", Image3: "img/OpalSeries/Opal1303.jpg", name: "Opal 13", ProductId: "Opal13" },
            { Image: "img/OpalSeries/Opal1401.jpg", Image2: "img/OpalSeries/Opal1402.jpg", Image3: "img/OpalSeries/Opal1403.jpg", name: "Opal 14", ProductId: "Opal14" },
            { Image: "img/OpalSeries/Opal1501.jpg", Image2: "img/OpalSeries/Opal1502.jpg", Image3: "img/OpalSeries/Opal1503.jpg", name: "Opal 15", ProductId: "Opal15" },
            { Image: "img/OpalSeries/Opal1601.jpg", Image2: "img/OpalSeries/Opal1602.jpg", Image3: "img/OpalSeries/Opal1603.jpg", name: "Opal 16", ProductId: "Opal16" },
            { Image: "img/OpalSeries/Opal1701.jpg", Image2: "img/OpalSeries/Opal1702.jpg", Image3: "img/OpalSeries/Opal1703.jpg", name: "Opal 17", ProductId: "Opal17" },
            { Image: "img/OpalSeries/Opal1801.jpg", Image2: "img/OpalSeries/Opal1802.jpg", Image3: "img/OpalSeries/Opal1803.jpg", name: "Opal 18", ProductId: "Opal18" },
            { Image: "img/OpalSeries/Opal1901.jpg", Image2: "img/OpalSeries/Opal1902.jpg", Image3: "img/OpalSeries/Opal1903.jpg", name: "Opal 19", ProductId: "Opal19" },
            { Image: "img/OpalSeries/Opal2001.jpg", Image2: "img/OpalSeries/Opal2002.jpg", Image3: "img/OpalSeries/Opal2003.jpg", name: "Opal 20", ProductId: "Opal20" },
        ],
        modelImage1: "img/StonexCollection/modelImage1.jpg",
        presentation: "img/StonexCollection/presentationModel.jpg"
    },

    ShineCollection: {
        banner: "",
        title: "Shine Collection",
        section1: [
            { Image: "img/ShineCollection/Shine0101.jpg", Image2: "img/ShineCollection/Shine0102.jpg", Image3: "img/ShineCollection/Shine0103.jpg", name: "Shine 1", ProductId: "Shine01" },
            { Image: "img/ShineCollection/Shine0201.jpg", Image2: "img/ShineCollection/Shine0202.jpg", Image3: "img/ShineCollection/Shine0203.jpg", name: "Shine 2", ProductId: "Shine02" },
            { Image: "img/ShineCollection/Shine0301.jpg", Image2: "img/ShineCollection/Shine0302.jpg", Image3: "img/ShineCollection/Shine0303.jpg", name: "Shine 3", ProductId: "Shine03" },
            { Image: "img/ShineCollection/Shine0401.jpg", Image2: "img/ShineCollection/Shine0402.jpg", Image3: "img/ShineCollection/Shine0403.jpg", name: "Shine 4", ProductId: "Shine04" },
            { Image: "img/ShineCollection/Shine0501.jpg", Image2: "img/ShineCollection/Shine0502.jpg", Image3: "img/ShineCollection/Shine0503.jpg", name: "Shine 5", ProductId: "Shine05" },
            { Image: "img/ShineCollection/Shine0601.jpg", Image2: "img/ShineCollection/Shine0602.jpg", Image3: "img/ShineCollection/Shine0603.jpg", name: "Shine 6", ProductId: "Shine06" },
            { Image: "img/ShineCollection/Shine0701.jpg", Image2: "img/ShineCollection/Shine0702.jpg", Image3: "img/ShineCollection/Shine0703.jpg", name: "Shine 7", ProductId: "Shine07" },
            { Image: "img/ShineCollection/Shine0801.jpg", Image2: "img/ShineCollection/Shine0802.jpg", Image3: "img/ShineCollection/Shine0803.jpg", name: "Shine 8", ProductId: "Shine08" },
            { Image: "img/ShineCollection/Shine0901.jpg", Image2: "img/ShineCollection/Shine0902.jpg", Image3: "img/ShineCollection/Shine0903.jpg", name: "Shine 9", ProductId: "Shine09" },
            { Image: "img/ShineCollection/Shine1001.jpg", Image2: "img/ShineCollection/Shine1002.jpg", Image3: "img/ShineCollection/Shine1003.jpg", name: "Shine 10", ProductId: "Shine10" },
            { Image: "img/ShineCollection/Shine1101.jpg", Image2: "img/ShineCollection/Shine1102.jpg", Image3: "img/ShineCollection/Shine1103.jpg", name: "Shine 11", ProductId: "Shine11" },
            { Image: "img/ShineCollection/Shine1201.jpg", Image2: "img/ShineCollection/Shine1202.jpg", Image3: "img/ShineCollection/Shine1203.jpg", name: "Shine 12", ProductId: "Shine12" },
        ],
        section2: [
            { Image: "img/ShineCollection/Shine1301.jpg", Image2: "img/ShineCollection/Shine1302.jpg", Image3: "img/ShineCollection/Shine1303.jpg", name: "Shine 13", ProductId: "Shine13" },
            { Image: "img/ShineCollection/Shine1401.jpg", Image2: "img/ShineCollection/Shine1402.jpg", Image3: "img/ShineCollection/Shine1403.jpg", name: "Shine 14", ProductId: "Shine14" },
            { Image: "img/ShineCollection/Shine1501.jpg", Image2: "img/ShineCollection/Shine1502.jpg", Image3: "img/ShineCollection/Shine1503.jpg", name: "Shine 15", ProductId: "Shine15" },
            { Image: "img/ShineCollection/Shine1601.jpg", Image2: "img/ShineCollection/Shine1602.jpg", Image3: "img/ShineCollection/Shine1603.jpg", name: "Shine 16", ProductId: "Shine16" },
            { Image: "img/ShineCollection/Shine1701.jpg", Image2: "img/ShineCollection/Shine1702.jpg", Image3: "img/ShineCollection/Shine1703.jpg", name: "Shine 17", ProductId: "Shine17" },
            { Image: "img/ShineCollection/Shine1801.jpg", Image2: "img/ShineCollection/Shine1802.jpg", Image3: "img/ShineCollection/Shine1803.jpg", name: "Shine 18", ProductId: "Shine18" },
            { Image: "img/ShineCollection/Shine1901.jpg", Image2: "img/ShineCollection/Shine1902.jpg", Image3: "img/ShineCollection/Shine1903.jpg", name: "Shine 19", ProductId: "Shine19" },
            { Image: "img/ShineCollection/Shine2001.jpg", Image2: "img/ShineCollection/Shine2002.jpg", Image3: "img/ShineCollection/Shine2003.jpg", name: "Shine 20", ProductId: "Shine20" },
            { Image: "img/ShineCollection/Shine2101.jpg", Image2: "img/ShineCollection/Shine2102.jpg", Image3: "img/ShineCollection/Shine2103.jpg", name: "Shine 21", ProductId: "Shine21" },
        ],
        modelImage1: "",
        presentation: ""
    },
}



