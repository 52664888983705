
import React from 'react';
import Theme from '../Theme/Theme';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Image from 'material-ui-image'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const useStyles = makeStyles((theme) => ({
    media: {
        marginLeft: '1em',
    },
    InstagramGrid: {
        marginTop: '1em',
    }
}));
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const SectionTitle = withStyles({
    root: {
        fontFamily: "Roboto",
        fontWeight: "Medium"
    }
})(Typography);

var data = [
    {
        "image": "img/InsSlide1.jpg"
    },
    {
        "image": "img/InsSlide2.jpg"
    },
    {
        "image": "img/InsSlide3.jpg"
    },
    {
        "image": "img/InsSlide4.jpg"
    },
    {
        "image": "img/InsSlide5.jpg"
    }
]

const InstagramFlowSlider = () => {
    const classes = useStyles();
    return (
        <div>
            <SectionTitle variant="subtitle2" color="secondary">
                INSTAGRAM FLOW
            </SectionTitle>
            <Carousel className={classes.InstagramGrid} responsive={responsive}>
                {
                    data.map((card) =>
                        <Image
                            aspectRatio={270 / 254}
                            cover={true}
                            className={classes.media}
                            src={card.image}
                        />
                    )
                }
            </Carousel>
        </div>

    );
}
export default InstagramFlowSlider;