export const MenuItems = [
    {
        name: "COLLECTIONS",
        path: "Collections",
        accordion: true,
        submenus: [
            {
                name: "Neso Collection",
                path: "NesoCollection",
                img: "img/ModelImages/model1.jpeg",
                BigImg: "img/CollectionMenuImg/1.jpg"
            },
            {
                name: "Opal Series",
                path: "OpalSeries",
                img: "img/ModelImages/model2.jpeg",
                BigImg: "img/CollectionMenuImg/2.jpg"
            },
            {
                name: "Stonex Collection",
                path: "StonexCollection",
                img: "img/ModelImages/model3.jpeg",
                BigImg: "img/CollectionMenuImg/3.jpg"
            },
            {
                name: "Shine Collection",
                path: "ShineCollection",
                img: "img/ModelImages/model4.jpeg",
                BigImg: ""
            },
            {
                name: "Huggies Collection",
                path: "HuggiesCollection",
                img: "img/ModelImages/model5.jpeg",
                BigImg: ""
            },
            {
                name: "Mega Collection",
                path: "MegaCollection",
                img: "img/ModelImages/model6.jpeg",                
                BigImg: ""
            }

        ]
    },
    {
        name: "ALL JEWELLERS",
        path: "AllJewellers",
        accordion: true,
        submenus: [
            {
                name: "Necklaces",
                path: "Necklaces",
                img: "img/NesoCollection/Neso2401.jpg",
                BigImg: "img/CollectionMenuImg/Necklaces.jpg"
            },
            {
                name: "Ring's",
                path: "Rings",
                img: "img/StonexCollection/Stonex3101.jpg",
                BigImg: "img/CollectionMenuImg/Rings.jpg"
            },
            {
                name: "Earring's",
                path: "Earrings",
                img: "img/NesoCollection/Neso1501.jpg",
                BigImg: "img/CollectionMenuImg/Earrings.jpg"
            },
            {
                name: "Bracelet's",
                path: "Bracelets",
                img: "img/NesoCollection/Neso2601.jpg",
                BigImg: ""
            },
            {
                name: "Men Ring's",
                path: "MenRings",
                img: "img/MegaCollection/Mega1201.jpg",
                BigImg: ""
            },
            {
                name: "Pendant's",
                path: "Pendants",
                img: "img/MegaCollection/Mega1201.jpg",
                BigImg: ""
            }
            
        ]

    },
    {
        name: "DISCOVER",
        path: "/Discover",
        accordion: false,
    },
    {
        name: "LOOKBOOK",
        path: "/Lookbook",
        accordion: false,
    },
    {
        name: "CONTACT",
        path: "/Contact",
        accordion: false,

    }
]
